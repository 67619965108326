import React from 'react'

export default () => (
  <section id="skills">
    <h2>Skills</h2>

    <h3>Languages</h3>
    <p>JavaScript, Node.js, Java, Python, Bash</p>

    <h3>Frameworks, Libraries, &amp; Tools</h3>
    <p>React, Redux, Webpack, Sass, Jest, Mocha, Chai, Selenium, Cypress, Gatsby</p>

    <h3>Other</h3>
    <p>Linux, Git, Jira, JSON, CSS, HTML</p>
  </section>
);
